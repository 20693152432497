<template>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container">
            <!-- Logo -->
            <router-link to="/" class="navbar-brand">
                <img src="@/assets/images/logo1.png" alt="TechLogo" />
            </router-link>
            <!-- Toggle Button -->
            <button class="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Sidebar -->
            <!-- ref="my_offcanvas" -->
            <div class="sidebar offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel" >
                <!-- Sidebar Header -->
                <div class="offcanvas-header border-bottom">
                    <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                        <img src="@/assets/images/logo1.png" alt="TechLogo" />
                    </h5>
                    <button type="button" class="btn-close shadow-none" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <!-- Sidebar Body -->
                <div class="offcanvas-body d-flex flex-column flex-lg-row">
                    <!-- fs-5 -->
                    <ul class="navbar-nav justify-content-center align-items-center flex-grow-1 pe-3">
                        <li class="nav-item mx-5">
                            <!-- From router file -->
                            <!-- @click="hiddenOffcanvas(true)" -->
                            <router-link :to="{ name: 'Home' }" class="nav-link" aria-current="page"
                                exact>الرئيسية</router-link>
                        </li>
                        <li class="nav-item mx-5">
                            <router-link :to="{ name: 'OurFields' }" class="nav-link"
                                aria-current="page">مجالاتنا</router-link>
                        </li>
                        <li class="nav-item mx-5">
                            <router-link :to="{ name: 'OurProducts' }" class="nav-link"
                                aria-current="page">منتجاتنا</router-link>
                        </li>
                        <li class="nav-item mx-5">
                            <router-link :to="{ name: 'AbilitiesPictures' }" class="nav-link" aria-current="page">قدراتك في
                                صور</router-link>
                        </li>
                        <li class="nav-item mx-5">
                            <router-link :to="{ name: 'OurExperts' }" class="nav-link"
                                aria-current="page">خبراؤنا</router-link>
                        </li>
                        <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li>
                            <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </li> -->
                    </ul>
                    <!-- <form class="d-flex mt-3" role="search">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                    </form> -->
                    <!-- Login / Sign up -->
                    <!-- <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3">
                    <a href="#login">Login</a>
                    <a href="#signup" class="text-white text-decoration-none px-3 py-1 rounded-4" style="background-color: cadetblue;">Sign Up</a>
                    </div> -->
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: 'Navbar',
    // https://stackoverflow.com/questions/65447868/show-or-hide-offcanvas-menu-when-router-link-is-clicked
    // https://forum.bootstrapstudio.io/t/how-to-offcanvas-close-after-click-on-anchor-link/9139
    // https://fontawesomeicons.com/fa/vue-change-text-color-on-hover
    // https://www.youtube.com/watch?v=5y6NFy5M9z8
    // https://coreui.io/docs/components/offcanvas/
    /* methods: {
        hiddenOffcanvas(isClicking) {
            if (isClicking) {
                this.$refs.my_offcanvas.style.visibility = 'hidden';
                // .offcanvas-backdrop.show
                // .offcanvas-backdrop.fade
                // this.offcanvas-backdrop.style.opacity == '1';
            }
        },
        showOffcanvas(isClicking){
            if (isClicking) {
                this.$refs.my_offcanvas.style.visibility = 'visible';
            }
        },
    }, */
};

</script>

<style scoped lang="scss">
nav {

    background: $White;
    box-shadow: 0px 4px 4px 0px rgba(52, 52, 52, 0.25);

    // width: 1440px;
    width: 100%;
    height: 64px;

    a {
        color: $Secondary !important;
        width: max-content;
    }

    a:hover,
    a.router-link-exact-active {
        color: $Primary !important;
        font-weight: bold !important;
    }

    img {
        width: 117px;
        height: 48px;
    }

    ul {

        @include breakpoints(x-small) {
            justify-content: start !important;
        }

        @include breakpoints(small) {
            justify-content: start !important;
        }


    }

}</style>
