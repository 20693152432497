<template>
    <!-- ****************************** Start Company Products ************************************* -->

    <!-- Company Products -->

    <div id="company_products">

        <div class="video">
            <!-- <video autoplay loop muted src="../../assets/home/video/SAUDIVIDEO.mp4" ></video> -->
            <img src="@/assets/images/our_products/company_products.jpg" alt="Company products">
        </div>


        <div class="content">
            <h3 class="text-center fw-bold">منتجات الشركة</h3>
        </div>


    </div>

    <!-- ****************************** End Company Products ************************************* -->
</template>

<script>
export default {
    name: 'CompanyProducts',
}
</script>

<style lang="scss" scoped>
// ----------------- Company Products ------------------------------ //

#company_products {

    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: $Primary;

    .video {

        height: 761px;

        @include breakpoints(x-small) {
            height: 678px;
        }

        @include breakpoints(small) {
            height: 678px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.6;
        }
    }

    .content {

        position: absolute;
        left: 50%;
        top: 90%;
        transform: translate(-50%, -50%);

        h3 {

            color: $White;
            font-size: 40px;
            // font-size: xxx-large;

            @include breakpoints(x-small) {
                // font-size: large;
                font-size: 18px;
            }

            @include breakpoints(small) {
                // font-size: large;
                font-size: 18px;
            }

            /* @include breakpoints(medium) {
                font-size: x-large;
            } */

            /* @include breakpoints(large) {
                font-size: xx-large;
            } */

        }
    }


}
</style>