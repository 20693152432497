<template>
    <OurFields/>
    <Footer/>
</template>

<script>

import OurFields from '@/components/our_fields/OurFields.vue';

export default {

    name: 'OurFieldsView',

    components:{
        OurFields,
    }
}
</script>

<style scoped lang="scss">

</style>