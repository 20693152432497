<template>
    <!-- ****************************** Start Certified Platform ************************************* -->

    <div id="certified">

        <div class="container">

            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 title_col">

                    <h3 class="fw-bold">منصة معتمد</h3>

                    <p class="text-justify">منظومة متكاملة تقدم اعتمادات من المنظمات العالمية واستشارات تعليمية بالإضافة
                        إلى الاختبارات الدورية</p>

                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 desc_col">

                    <div class="desc_wrapper">

                        <h5 class="fw-bold">
                            <font-awesome-icon :icon="['fas', 'fa-square-check']" />
                            اعتمادات
                        </h5>

                        <p class="text-justify">توفر منصة معتمد اعتمادات للأفراد والجهات بالشراكة مع أكبر المنظمات
                            والمعاهد الاستشارية في العالم</p>

                    </div>

                    <div class="desc_wrapper">

                        <h5 class="fw-bold">
                            <font-awesome-icon :icon="['fas', 'fa-square-check']" />
                            استشارات
                        </h5>

                        <p class="text-justify">احصل على استشارات خبراء التعليم بشكل عام بالإضافة إلى الاستشارات الخاصة
                            بتعليم STEAM واتخذ القرارات المناسبة</p>

                    </div>

                    <div class="desc_wrapper">

                        <h5 class="fw-bold">
                            <font-awesome-icon :icon="['fas', 'fa-square-check']" />
                            اختبارات
                        </h5>

                        <p class="text-justify">اختباراتنا الدورية تمكنك من المتابعة والتقييم المستمر لمعرفة نقاط القوة
                            والضعف لدى الطلاب لتتمكن من تقديم أفضل جودة تعليمية</p>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <!-- ****************************** End Certified Platform ************************************* -->
</template>

<script>
export default {
    name: 'CertifiedPlatform',
}
</script>

<style lang="scss" scoped>
// ------------------ Certified Platform ----------------------------- //

#certified {

    margin-top: 40px;
    margin-bottom: 40px;
    text-align: right;

    .title_col {

        h3 {
            background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
            // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -o-background-clip: text;
            -ms-background-clip: text;
            background-clip: text;
            color: transparent;
            margin-bottom: 16px;

            @include breakpoints(x-small) {
                text-align: center;
            }
        }

        p {
            color: $heading;
        }

    }

    .desc_col {

        .desc_wrapper {

            margin-bottom: 30px;

            h5 {

                background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
                // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -o-background-clip: text;
                -ms-background-clip: text;
                background-clip: text;
                color: transparent;

                svg {
                    width: 24px;
                    height: 24px;
                    color: $Primary;
                }

            }

            p {
                color: $heading;
            }
        }

    }

}
</style>