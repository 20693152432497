<template>
  <Home />
  <Footer />
</template>

<script>

import Home from '@/components/home/Home.vue';

export default {

  name: 'HomeView',

  components: {
    Home,
  }

}
</script>

<style scoped lang="scss"></style>
