<template>
    <div class="container">

        <!-- ******************************************************************* -->

        <h3 class="text-center fw-bold">مجالات الشركة</h3>

        <!-- width:793px;-->
        <p class="text-center mainParagraph">
            نقدم خدمات الإستشارات والتدريب كما نعمل على زيادة كفاءة مخرجات التعليم وتوفير الأدوات الفنية
            والعلمية والتشغيل بناء على سياسات وأدلة تشغيلية واضحة
        </p>


        <!-- ******************************************************************* -->

        <!-- For Large Screen -->

        <div class="large_screen">

            <!-- https://getbootstrap.com/docs/5.3/components/card/ -->

            <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">

                <!-- row-cols-1 row-cols-md-5 -->

                <div class="row row-cols-lg-5 g-4">

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <!--  animate__animated animate__flash animate__delay-2s animate__slow -->
                                <img src="@/assets/images/our_fields/training.png" class="img-fluid" alt="Training">
                                <h5 class="card-title fw-bold mt-4">التدريب</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/consulting.png" class="img-fluid" alt="Consulting">
                                <h5 class="card-title fw-bold mt-4">الإستشارات</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/certification.png" class="img-fluid"
                                    alt="Certification">
                                <h5 class="card-title fw-bold mt-4">الشهادات</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/preparation.png" class="img-fluid" alt="Preparation">
                                <h5 class="card-title fw-bold mt-4">التجهيزات</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/employment.png" class="img-fluid" alt="Employment">
                                <h5 class="card-title fw-bold mt-4">التشغيل</h5>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

        <!-- For Medium & Small Screen -->

        <div class="small_screen">

            <div id="FieldSmallExampleIndicators" class="carousel slide d-block d-sm-block d-md-block d-lg-none d-xl-none"
                data-bs-ride="carousel">

                <div class="carousel-inner">

                    <div class="carousel-item active">
                        <div class="card-wrapper">
                            <div class="card mx-2">
                                <div class="card-body text-center">
                                    <img src="@/assets/images/our_fields/training.png" alt="Training">
                                    <h5 class="fw-bold mt-4">التدريب</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="card-wrapper">
                            <div class="card mx-2">
                                <div class="card-body text-center">
                                    <img src="@/assets/images/our_fields/consulting.png" alt="Consulting">
                                    <h5 class="fw-bold mt-4">الإستشارات</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="card-wrapper">
                            <div class="card mx-2">
                                <div class="card-body text-center">
                                    <img src="@/assets/images/our_fields/certification.png" alt="Certification">
                                    <h5 class="fw-bold mt-4">الشهادات</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="card-wrapper">
                            <div class="card mx-2">
                                <div class="card-body text-center">
                                    <img src="@/assets/images/our_fields/preparation.png" alt="Preparation">
                                    <h5 class="fw-bold mt-4">التجهيزات</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="card-wrapper">
                            <div class="card mx-2">
                                <div class="card-body text-center">
                                    <img src="@/assets/images/our_fields/employment.png" alt="Employment">
                                    <h5 class="fw-bold mt-4">التشغيل</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="d-flex justify-content-center">

                    <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                    <!-- Carousel controls -->
                    <button class="carousel-control-next rounded" type="button"
                        data-bs-target="#FieldSmallExampleIndicators" data-bs-slide="next">
                        <font-awesome-icon :icon="['fas', 'arrow-right']" />
                    </button>
                    <button class="carousel-control-prev rounded" type="button"
                        data-bs-target="#FieldSmallExampleIndicators" data-bs-slide="prev">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </button>
                </div>

            </div>

        </div>

        <!-- ******************************************************************* -->

        <!-- Training -->

        <div class="row fieldRow">

            <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                <div>

                    <h5 class="fw-bold">التدريب</h5>

                    <p class="mt-4">
                        نعتمد بشكل أساسي على كفاءاتنا المتخصصة لنقل أفضل
                        الممارسات العالمية وموائمتها محليا لضمان إحداث نقلة
                        في تأهيل القدرات البشرية ونقل الخبرات واستدامتها.
                    </p>

                </div>

            </div>

            <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                <img src="@/assets/images/our_fields/trainingPic.jpg" alt="Training Picture" class="rounded">
            </div>

        </div>

        <!-- ******************************************************************* -->

        <!-- Consulting -->

        <div class="row fieldRow">

            <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                <img src="@/assets/images/our_fields/consultingPic.jpg" alt="Consulting Picture" class="rounded">
            </div>

            <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                <div>

                    <h5 class="fw-bold">الإستشارات</h5>

                    <p class="mt-4">
                        نقدم خدمات الاستشارات التعليمية بالشراكة مع أكبر بيوت الخبرة العالمية في تطوير بيئة
                        التعليم والمعتمدة
                        على المهارات التطبيقية لتتواكب مع متطلبات وظائف القرن الحادي والعشرين من خلال خبراء متخصصين في
                        مجالات عديدة ومتقدمة.
                    </p>

                </div>

            </div>

        </div>

        <!-- ******************************************************************* -->

        <!-- Certification -->

        <div class="row fieldRow">

            <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                <div>

                    <h5 class="fw-bold">الشهادات الإحترافية</h5>

                    <p class="mt-4">
                        نعمل على زيادة كفاءة مخرجات التعليم وربطها بمتطلبات سوق العمل من خلال تمثيل بيوت الخبرة
                        العالمية
                        بالإضافة إلى العمل مع بيوت الخبرة المحلية لبناء برامج احترافية وطنية في تأهيل القدرات البشرية.
                    </p>

                </div>

            </div>

            <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                <img src="@/assets/images/our_fields/certificationPic.jpg" alt="Certification Picture" class="rounded">
            </div>

        </div>

        <!-- ******************************************************************* -->

        <!-- Preparation -->

        <div class="row fieldRow">

            <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                <img src="@/assets/images/our_fields/preparationPic.jpg" alt="Preparation Picture" class="rounded">
            </div>

            <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                <div>

                    <h5 class="fw-bold">التجهيزات</h5>

                    <p class="mt-4">
                        تعتمد منظومتنا على آلية مدروسة لعملية التجهيزات ومبنية على توفير
                        الأدوات الفنية والعلمية المتوافقة مع
                        عملية التشغيل من خلال التكامل بين التعليم النظري والتطبيق العملي لضمان كفاءة التشغيل والجودة.
                    </p>

                </div>

            </div>

        </div>

        <!-- ******************************************************************* -->

        <!-- Employment -->

        <div class="row fieldRow">

            <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                <div>

                    <h5 class="fw-bold">التشغيل</h5>

                    <p class="mt-4">
                        تتم عملية التشغيل بناء على سياسات وأدلة تشغيلة واضحة وفق معايير محددة وبتقارير دورية
                        لزيادة كفاءة وجودة المخرجات بما يساعد على ضمان عملية التشغيل ونقل المعرفة.
                    </p>

                </div>

            </div>

            <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                <img src="@/assets/images/our_fields/employmentPic.jpg" alt="Employment Picture" class="rounded">
            </div>

        </div>

        <!-- ******************************************************************* -->

    </div>
</template>

<script>

export default {

    name: 'OurFields',

}

</script>

<style lang="scss" scoped>
.container {

    // For Top : 64 px + 40 px = 104px
    // For Bottom : 40px
    margin: 104px auto 40px auto;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    .mainParagraph {
        color: #2A3641;
        margin-bottom: 24px;
    }

    // For All Screen

    .card {

        background-color: #DAEDED;
        border: none;

        .card-body {

            img {
                width: 80px;
                height: 80px;
            }

            img:hover {
                -webkit-animation: blinker 1s linear infinite;
                -moz-animation: blinker 1s linear infinite;
                -ms-animation: blinker 1s linear infinite;
                -o-animation: blinker 1s linear infinite;
                animation: blinker 1s linear infinite;
            }

            h5 {
                background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
                // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -o-background-clip: text;
                -ms-background-clip: text;
                background-clip: text;
                // -webkit-text-fill-color: transparent;
                color: transparent;
            }

        }

    }

    // For Large Screen

    // For Medium & Small Screen

    .small_screen {
        .carousel {
            .carousel-inner .carousel-item {

                transition: transform 2.5s ease-in-out;

            }

            .carousel-control-prev,
            .carousel-control-next {
                position: static;
                padding-top: 16px;

            }

            .carousel-control-prev svg,
            .carousel-control-next svg {
                color: $Primary;
                width: 24px;
                height: 24px;
            }

        }

    }

    // 

    .fieldRow {

        margin-top: 80px;

        @include breakpoints(x-small) {

            margin-top: 40px;

            display: flex;

            flex-direction: column;

            .fieldAbout {

                order: 1;

                text-align: center;

            }

            .fieldImg {

                order: 2;

                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

        }

        @include breakpoints(small) {

            margin-top: 40px;

            display: flex;

            flex-direction: column;

            .fieldAbout {

                order: 1;

                text-align: center;

            }

            .fieldImg {

                order: 2;

                width: 90%;
                margin-left: auto;
                margin-right: auto;

            }

        }

        .fieldAbout {

            display: table;

            div {
                display: table-cell;
                vertical-align: middle;
                color: $heading;
            }

        }

        // https://dev.to/ellen_dev/two-ways-to-achieve-an-image-colour-overlay-with-css-eio

        .fieldImg {

            overflow: hidden;
            background-color: $Primary;
            padding: 0;
            border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.75;
            }

        }

    }

}

// For Animation
@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-moz-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-o-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
</style>