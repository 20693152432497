<template>
    <!-- ****************************** Start Commercial Concession Elements ************************************* -->

    <div id="elements">

        <div class="container">

            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 element_text">

                    <h3 class="fw-bold">عناصر الإمتياز التجاري</h3>

                    <p>ستحصل على مجموعة متكاملة من الأدوات والأنظمة والتدريبات لتضمن أفضل تطبيق ممكن ل STEAM class داخل
                        الوحدات الدراسية</p>


                </div>

                <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block col-lg-6 col-xl-6 element_image">

                    <div class="image_wrapper">
                        <img src="@/assets/images/our_products/commercial_concession_ele.png" alt="Element Image"
                            class="img-fluid">
                    </div>

                </div>

                <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none col-sm-12 col-md-6 element_icons">

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/1.svg" alt="">
                        التجهيزات
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/2.svg" alt="">
                        أداة التشغيل
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/3.svg" alt="">
                        التقارير الدورية
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/4.svg" alt="">
                        التدريب التأهيلي
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/5.svg" alt="">
                        السجل المهاري
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/6.svg" alt="">
                        تطبيق ولي الأمر
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/7.svg" alt="">
                        منصة المحتوى التدريبي
                    </p>

                    <!-- <i class="fa-solid fa-chart-column"></i> -->
                    <!-- <i class="fa-regular fa-file-lines"></i> -->

                </div>


            </div>

        </div>

    </div>

    <!-- ****************************** End Commercial Concession Elements ************************************* -->
</template>

<script>
export default {
    name: 'CommercialConcessionElements',
}
</script>

<style lang="scss" scoped>
// ------------------ Commercial Concession Elements ----------------------------- //

#elements {

    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $productColor;
    color: $White;
    text-align: right;

    .element_text {

        h3 {

            margin-bottom: 16px;

            @include breakpoints(x-small) {
                text-align: center;
            }

            @include breakpoints(small) {
                text-align: center;
            }

        }

    }

    .element_image .image_wrapper {

        max-width: 662px;
        height: 158px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }

    .element_icons {

        p {

            @include breakpoints(x-small) {
                display: inline-block;
                width: 50%;
            }

            @include breakpoints(small) {
                display: inline-block;
                width: 50%;
            }

            svg {

                width: 24px;
                height: 24px;
                margin-left: 8px;

            }

        }

    }

}
</style>