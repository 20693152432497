<template>
    <div class="container">

        <h3 class="text-center fw-bold">قدراتك في صور</h3>

        <!-- https://getbootstrap.com/docs/5.3/components/carousel/#indicators -->
        <!-- https://codingyaar.com/bootstrap-carousel-slider-with-thumbnails/ -->
        <!-- https://www.youtube.com/watch?v=NAMvdbS4lk4 -->

        <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel"
            data-bs-keyboard="true">

            <!-- Top Image -->
            <div class="carousel-inner">

                <div class="carousel-item active rounded">
                    <img src="@/assets/images/abilities_pictures/Rectangle.jpg" class="d-block w-100 rounded"
                        alt="Ability Image">
                </div>

                <div class="carousel-item rounded">
                    <img src="@/assets/images/abilities_pictures/Rectangle2.jpg" class="d-block w-100 rounded"
                        alt="Ability Image">
                </div>

                <div class="carousel-item rounded">
                    <img src="@/assets/images/abilities_pictures/Rectangle3.jpg" class="d-block w-100 rounded"
                        alt="Ability Image">
                </div>

                <div class="carousel-item rounded">
                    <img src="@/assets/images/abilities_pictures/Rectangle4.jpg" class="d-block w-100 rounded"
                        alt="Ability Image">
                </div>

            </div>

            <!-- Indicator Image -->
            <div class="carousel-indicators d-none d-sm-none d-md-block d-lg-block d-xl-block">

                <div class="row">

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                            class="active thumbnail rounded" aria-current="true" aria-label="Slide 1">
                            <img src="@/assets/images/abilities_pictures/Rectangle.jpg" class="d-block rounded"
                                alt="Ability Image Indicator">
                        </button>
                    </div>

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                            class="thumbnail rounded" aria-label="Slide 2">
                            <img src="@/assets/images/abilities_pictures/Rectangle2.jpg" class="d-block rounded"
                                alt="Ability Image Indicator">
                        </button>
                    </div>

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                            class="thumbnail rounded" aria-label="Slide 3">
                            <img src="@/assets/images/abilities_pictures/Rectangle3.jpg" class="d-block rounded"
                                alt="Ability Image Indicator">
                        </button>
                    </div>

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                            class="thumbnail rounded" aria-label="Slide 4">
                            <img src="@/assets/images/abilities_pictures/Rectangle4.jpg" class="d-block rounded"
                                alt="Ability Image Indicator">
                        </button>
                    </div>

                </div>

            </div>

            <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
            <!-- Carousel controls -->
            <div class="d-flex justify-content-center">
                <button class="carousel-control-next rounded d-inline d-sm-inline d-md-none d-lg-none d-xl-none"
                    type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
                <button class="carousel-control-prev rounded d-inline d-sm-inline d-md-none d-lg-none d-xl-none"
                    type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" />
                </button>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: 'AbilitiesPictures',
}
</script>

<style lang="scss" scoped>
.container {

    // For Top : 64 px + 40 px = 104px
    // For Bottom : 40px
    margin: 104px auto 40px auto;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    .carousel-inner {

        border-bottom: 1px solid $Secondary;
        padding-bottom: 20px;

        @include breakpoints(small) {
            border-bottom: none;
            padding-bottom: 0;
        }

        @include breakpoints(x-small) {
            border-bottom: none;
            padding-bottom: 0;
        }

        .carousel-item {
            // https://stackoverflow.com/questions/41590469/how-to-create-a-inner-border-for-a-box-in-html
            // border: 2px solid $Primary;
            // outline: 1px dashed $Secondary;
            outline: 2px solid $Primary;
            outline-offset: -20px;

            // transition: transform 2s ease-in-out;
            @include breakpoints(small) {
                outline-offset: -10px;
            }

            @include breakpoints(x-small) {
                outline-offset: -10px;
            }
        }

    }

    .carousel-indicators {

        position: static;
        padding-top: 20px;
        margin: 0;

        button.thumbnail {
            // width: 250px;
            width: 100%;
            height: 100%;
            border: 2px solid $Secondary;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        button.thumbnail.active {
            // visibility: hidden;
            border: 2px solid $Primary;
        }

        button.thumbnail:not(.active) {
            opacity: 0.5;
        }

        button.thumbnail:hover {
            opacity: 0.75;
        }

        button img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: luminosity;
        }

    }

    .carousel-control-prev,
    .carousel-control-next {
        position: static;
        padding-top: 16px;

    }

    .carousel-control-prev svg,
    .carousel-control-next svg {
        color: $Primary;
        width: 24px;
        height: 24px;
    }

}
</style>