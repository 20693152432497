<template>
    <!-- ******************************  Start Company Products ************************************* -->

    <div id="company_products">

        <!-- ******************************  Start Container ************************************* -->

        <div class="container">

            <h3 class="text-center fw-bold">منتجات الشركة</h3>

            <!-- ******************************  Top ************************************* -->

            <div class="main_div" v-if="mainDiv">

                <p class="text-center mainParagraph">
                    نقدم منتجات عديدة منها تعليم STEAM وهو من أهم الأساليب والركائز التعليمية بالإضافة إلى منصة معتمد
                    التي تهدف إلى تقديم الاستشارات والدراسات التعليمية ونعمل أيضا مع بيوت الخبرة العالمية لتمثيلها محليا
                    كما أننا نعمل مع الأكاديميات المحلية والعالمية على هاكاثونات ومعسكرات قصيرة ومكثفة تعتمد على
                    إحتياجات السوق.
                </p>

                <!-- https://getbootstrap.com/docs/5.3/components/card/ -->

                <div class="row row-cols-1 row-cols-md-4 g-4">

                    <div class="col text-center">
                        <div class="card card-block d-flex" @click="mainDiv = false; commercialDiv = true;">
                            <img src="@/assets/images/our_fields/employmentPic.jpg" class="card-img img-fluid" alt="">
                            <div class="card-img-overlay align-items-center d-flex justify-content-center">
                                <h5 class="card-title text-white fw-bolder">الإمتياز التجاري</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col text-center">
                        <div class="card card-block d-flex" @click="mainDiv = false; certificatesDiv = true;">
                            <img src="@/assets/images/home/professional_certificates_img.jpg" class="card-img img-fluid"
                                alt="">
                            <div class="card-img-overlay align-items-center d-flex justify-content-center">
                                <h5 class="card-title text-white fw-bolder">الشهادات الإحترافية</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col text-center">
                        <div class="card card-block d-flex" @click="mainDiv = false; certifiedDiv = true;">
                            <img src="@/assets/images/our_fields/consultingPic.jpg" class="card-img img-fluid" alt="">
                            <div class="card-img-overlay align-items-center d-flex justify-content-center">
                                <h5 class="card-title text-white fw-bolder">منصة معتمد</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col text-center">
                        <div class="card card-block d-flex" @click="mainDiv = false; campsDiv = true;">
                            <img src="@/assets/images/our_products/father-sons-making-robot.jpg" class="card-img img-fluid"
                                alt="">
                            <div class="card-img-overlay align-items-center d-flex justify-content-center">
                                <h5 class="card-title text-white fw-bolder">هاكاثونات ومعسكرات مهارات الجيل الصناعي
                                    الرابع
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- ****************************** Commercial concession ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" productDiv" v-if="commercialDiv">

                    <div class="row productRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="mainDiv = true; commercialDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productAbout">

                            <div>

                                <h5 class="fw-bold">الإمتياز التجاري</h5>

                                <p class="mt-4"> يعمل نظام تطبيق STEAM class على تأهيل الطلاب للالتحاق بوظائف المستقبل
                                    التى تحتاج المهارات والخبرة العملية في مجالات الهندسة، والتكنولوجيا، والعلوم،
                                    والفنون والرياضيات.</p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productImg">
                            <img src="@/assets/images/our_fields/employmentPic.jpg" alt="Commercial concession Picture"
                                class="rounded img-fluid">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ****************************** Professional certificates ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" productDiv" v-if="certificatesDiv">

                    <div class="row productRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="mainDiv = true; certificatesDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productAbout">

                            <div>

                                <h5 class="fw-bold">الشهادات الإحترافية</h5>

                                <p class="mt-4">نعمل بالشراكة مع بيوت الخبرة العالمية لتمثيلها محليا بالإضافة إلى
                                    امتلاكنا برامج احترافية متخصصة في مختلف القطاعات والمجالات ومتوافقة مع متطلبات سوق
                                    العمل المحلي، بهدف تعزيز المهارات والخبرات العملية. مع ضمان اكتساب الممارسين
                                    المهارات اللازمة في المجال طبقا لأعلى المعايير وأفضل الممارسات العالمية.</p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productImg">
                            <img src="@/assets/images/home/professional_certificates_img.jpg"
                                alt="Commercial concession Picture" class="rounded img-fluid">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ****************************** Certified platform ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" productDiv" v-if="certifiedDiv">

                    <div class="row productRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="mainDiv = true; certifiedDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productAbout">

                            <div>

                                <h5 class="fw-bold">منصة معتمد</h5>

                                <p class="mt-4">منظومة متكاملة تهدف إلى تقديم الاستشارات والدراسات بشكل عام والبرامج
                                    القائمة على تعليم STEM ومنح الاعتمادات للأفراد والجهات وفق منظومة إلكترونية بالشراكة
                                    مع المنظمات العالمية والمعاهد الاستشارية. </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productImg">
                            <img src="@/assets/images/our_fields/consultingPic.jpg" alt="Commercial concession Picture"
                                class="rounded img-fluid">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ****************************** Hackathons and fourth generation skills camps ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" productDiv" v-if="campsDiv">

                    <div class="row productRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="mainDiv = true; campsDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productAbout">

                            <div>

                                <h5 class="fw-bold">هاكاثونات ومعسكرات مهارات الجيل الصناعي الرابع</h5>

                                <p class="mt-4">نعمل مع الأكاديمية المحلية والعالمية على هاكاثونات ومعسكرات قصيرة ومكثفة
                                    تعتمد على الربط المباشر بين احتياجات سوق العمل والباحثين عن الوظائف من خلال برامج
                                    تدريبية عالية المستوى تبني مخرجاتها على المشاريع وإيجاد الحلول وتعزيز الإبداع
                                    والابتكار في المجالات الآتية</p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 productImg">
                            <img src="@/assets/images/our_products/father-sons-making-robot.jpg"
                                alt="Commercial concession Picture" class="rounded img-fluid">
                        </div>

                    </div>

                </div>

            </transition>

        </div>

        <!-- ******************************  End Container ************************************* -->

    </div>

    <!-- ******************************  End Company Products ************************************* -->
</template>

<script>
export default {

    name: 'CompanyProducts',

    data() {
        return {

            // -----------------  Company Products ------------------------------ //

            // https://www.youtube.com/watch?v=Y1qBr1VCm-A

            mainDiv: true,

            commercialDiv: false,

            certificatesDiv: false,

            certifiedDiv: false,

            campsDiv: false,

        };
    },

}
</script>

<style lang="scss" scoped>
// -----------------  Company Products ------------------------------ //

#company_products {

    background-color: $homeBG;
    padding-top: 40px;
    padding-bottom: 40px;

    h3 {
        color: $heading;
        margin-bottom: 24px;
    }

    // ------------- Top Div ------------- //

    .main_div {

        .mainParagraph {
            color: $homeParagraph;
            margin-bottom: 40px;
        }

        .card {

            // width: 288px;
            height: 260px;
            box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);
            background-color: $Primary;
            cursor: pointer;
            border: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.25;
            }

        }

    }

    // ------------- Description Div ------------- //

    .productDiv {

        position: relative;

        .productRow {

            margin: 0;

            .btn_box {

                position: absolute;
                left: 0px;
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                padding: 0;

                button {

                    padding: 0;

                    svg {
                        color: $Primary;
                    }

                }

            }

            .productAbout {

                display: table;
                margin-top: 50px;
                padding-right: 0;

                div {

                    display: table-cell;
                    vertical-align: middle;
                    color: $heading;

                    h5 {
                        @include breakpoints(x-small) {
                            text-align: center;
                        }

                        @include breakpoints(small) {
                            text-align: center;
                        }
                    }

                }

            }

            // https://dev.to/ellen_dev/two-ways-to-achieve-an-image-colour-overlay-with-css-eio

            .productImg {

                margin-top: 50px;
                overflow: hidden;
                background-color: $Primary;
                padding: 0;
                border-radius: 8px;
                height: 328px;
                // height: fit-content;

                @include breakpoints(x-small) {
                    margin-top: 10px;
                }

                @include breakpoints(small) {
                    margin-top: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.75;
                }

            }

        }

    }

}

// For Transition

.fade-enter-active {
    transition: opacity 1.5s ease;
}

.fade-leave-active {
    opacity: 0;
}

.fade-enter-from {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}
</style>