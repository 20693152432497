<template>
  <!-- import navbar in main.js file -->
  <Navbar />
  <router-view />
</template>

<script>

// import Navbar from "@/components/layouts/Navbar.vue";

export default {

  /* components: {
    Navbar,
  } */

}

</script>

<style lang="scss">
// @import url(https://db.onlinewebfonts.com/c/739571e5b3270dd49b19f413b1ef148e?family=F29LT+Bukra+Regular);

// @import "@/assets/scss/variables/_globalVars.scss";
// @import "@/assets/scss/helpers/_mixins.scss";

#app {
  font-family: F29LT Bukra Regular, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  color: $Secondary;
}
#app a{
  text-decoration: none;
}
</style>
