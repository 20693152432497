<template>
    <!-- ******************************  Start Our Experts  ************************************* -->

    <div id="our_experts">

        <!-- ******************************  Start Container ************************************* -->

        <!-- <div class="container"> -->

        <h3 class="text-center fw-bold">خبراؤنا</h3>

        <!-- https://www.youtube.com/watch?v=kHPm_AlxP7U -->
        <!-- https://www.youtube.com/watch?v=nS_Ht0lT-uQ -->

        <!-- For Large Screen -->

        <div class="large_screen d-none d-sm-none d-md-none d-lg-block d-xl-block">

            <div id="expertExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-keyboard="true">

                <div class="carousel-inner">

                    <div class="carousel-item active" data-bs-interval="13000">

                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/3.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Andrew B.Raupp
                                                <flag iso="us" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Founder & CEO</span> <br />
                                                STEAM.org Educational Research
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/2.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Mr. Brent Hutcheson
                                                <flag iso="za" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Director</span> <br />
                                                Hands on Technologies & Care for Education NPO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/1.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Gareth James
                                                <flag iso="gb" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Strategic Programs and Partnerships Manager at WRO</span> <br />
                                                Member board of trustees at Groundwork
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="carousel-item" data-bs-interval="13000">

                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/6.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Kobayashi Yasuhide
                                                <flag iso="jp" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>CEO</span> <br />
                                                <span>Afrel Co., Ltd </span> <br />
                                                Member of Information Processing Society of Jaban
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/5.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Danielle Pliska
                                                <flag iso="us" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Vice President</span> <br />
                                                FIRST
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/4.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Mr. Jonathan Hung
                                                <flag iso="sg" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Executive Chairman</span> <br />
                                                Singapore space and Technology Limited (SSTL)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="carousel-item" data-bs-interval="13000">

                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/7.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Lynette
                                                <flag iso="sg" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Managing Director</span> <br />
                                                Space Faculty
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/8.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Johnson Jan
                                                <flag iso="cn" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>CEO</span> <br />
                                                KK Intelligent Technology, Corp
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-12 col-xl-2">
                                        <img src="@/assets/images/our_experts/2.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-12 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Mr. Brent Hutcheson
                                                <flag iso="za" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Director</span> <br />
                                                Hands on Technologies & Care for Education NPO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="d-flex justify-content-center">

                    <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                    <!-- Carousel controls -->
                    <button class="carousel-control-next rounded" type="button" data-bs-target="#expertExampleIndicators"
                        data-bs-slide="next">
                        <font-awesome-icon :icon="['fas', 'arrow-right']" />
                    </button>
                    <button class="carousel-control-prev rounded" type="button" data-bs-target="#expertExampleIndicators"
                        data-bs-slide="prev">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </button>

                </div>

            </div>

        </div>

        <!-- For Small Screen -->

        <div class="small_screen d-block d-sm-block d-md-block d-lg-none d-xl-none">

            <div id="expertSmallExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                <div class="carousel-inner">

                    <div class="carousel-item active" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/3.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Andrew B.Raupp
                                                <flag iso="us" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Founder & CEO</span> <br />
                                                STEAM.org Educational Research
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/2.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Mr. Brent Hutcheson
                                                <flag iso="za" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Director</span> <br />
                                                Hands on Technologies & Care for Education NPO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/1.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Gareth James
                                                <flag iso="gb" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Strategic Programs and Partnerships Manager at WRO</span> <br />
                                                Member board of trustees at Groundwork
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/6.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Kobayashi Yasuhide
                                                <flag iso="jp" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>CEO</span> <br />
                                                <span>Afrel Co., Ltd </span> <br />
                                                Member of Information Processing Society of Jaban
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/5.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Danielle Pliska
                                                <flag iso="us" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Vice President</span> <br />
                                                FIRST
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/4.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Mr. Jonathan Hung
                                                <flag iso="sg" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Executive Chairman</span> <br />
                                                Singapore space and Technology Limited (SSTL)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/7.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Lynette
                                                <flag iso="sg" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>Managing Director</span> <br />
                                                Space Faculty
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" data-bs-interval="13000">
                        <div class="card-wrapper animate__animated animate__fadeInDown animate__delay-0.9s animate__slow">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <img src="@/assets/images/our_experts/8.png" class="expertImg" alt="Expert Image">
                                    </div>
                                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                        <div class="card-body">
                                            <h6 class="card-title fw-bold">
                                                Johnson Jan
                                                <flag iso="cn" class="flagIco" />
                                            </h6>
                                            <p class="card-text">
                                                <span>CEO</span> <br />
                                                KK Intelligent Technology, Corp
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="d-flex justify-content-center">

                    <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                    <!-- Carousel controls -->
                    <button class="carousel-control-next rounded" type="button"
                        data-bs-target="#expertSmallExampleIndicators" data-bs-slide="next">
                        <font-awesome-icon :icon="['fas', 'arrow-right']" />
                    </button>
                    <button class="carousel-control-prev rounded" type="button"
                        data-bs-target="#expertSmallExampleIndicators" data-bs-slide="prev">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </button>

                </div>

            </div>

        </div>

        <!-- </div> -->

        <!-- ******************************  End Container ************************************* -->

    </div>

    <!-- ******************************  End Our Experts  ************************************* -->
</template>

<script>
export default {
    name: 'OurExperts',
}
</script>

<style lang="scss" scoped>
// ----------------- Our Experts ------------------------------ //

#our_experts {

    margin-top: 40px;
    margin-bottom: 40px;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    // For Large Screen

    @media screen and (min-width: 768px) {

        .large_screen .carousel .carousel-inner .carousel-item .card-wrapper {
            display: flex;
        }

        .large_screen .carousel .carousel-inner .carousel-item .card-wrapper .card {
            margin: auto 0.5em;
            width: calc(100%/3);
            box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);

        }

    }

    // For Small Screen

    @media screen and (max-width: 768px) {

        .small_screen .carousel .carousel-inner .carousel-item .card-wrapper {
            .card {
                box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);
            }
        }

    }

    // For All Screen

    .carousel {

        .carousel-inner {

            .carousel-item {

                transition: transform 2.5s ease-in-out;

                .card-wrapper {

                    direction: ltr;

                    .card {

                        border: 1px solid $Primary;
                        background-color: $Secondary;
                        padding: 24px 16px;
                        height: 200px;

                        @include breakpoints(x-small) {
                            height: 250px;
                            margin-left: 5px; 
                            margin-right: 5px;
                        }

                        @include breakpoints(small) {
                            margin-left: 5px; 
                            margin-right: 5px;
                        }

                        @include breakpoints(medium) {
                            margin-left: 5px; 
                            margin-right: 5px;
                        }

                        @include breakpoints(large) {
                            height: 270px;
                        }

                        .expertImg {

                            width: 72px;
                            height: 72px;

                            @include breakpoints(x-small) {
                                margin-bottom: 10px;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            @include breakpoints(large) {
                                margin-bottom: 10px;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }

                        }

                        .card-body {

                            padding-top: 0;

                            @include breakpoints(x-small) {
                                padding: 0;
                            }

                            @include breakpoints(large) {
                                padding: 0;
                            }

                            @include breakpoints(x-Large) {
                                margin-inline-start: 10px;
                            }

                            h6 {

                                color: #8EC8C9;
                                border-bottom: 1px solid #8EC8C9;
                                padding-bottom: 8px;

                                background-image: url("@/assets/images/our_experts/Group.png");
                                background-size: 104px 16px;
                                background-repeat: no-repeat;
                                // background-position: 178px 13px;
                                background-position: 100% 110%;

                                /* @include breakpoints(large) {
                                    background-image: none;
                                } */

                                /* @include breakpoints(x-Large) {
                                    background-image: none;
                                } */

                                .flagIco {
                                    float: inline-end;
                                }

                            }

                            p {

                                color: $White;

                                span {
                                    color: $gray;
                                }

                            }

                        }



                    }

                }

            }

        }

        .carousel-control-prev,
        .carousel-control-next {
            position: static;
            padding-top: 16px;

        }

        .carousel-control-prev svg,
        .carousel-control-next svg {
            color: $Primary;
            width: 24px;
            height: 24px;
        }

    }

}
</style>