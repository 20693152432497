<template>
    <!-- https://www.w3schools.com/bootstrap4/bootstrap_cards.asp -->

    <div class="container">

        <h3 class="text-center fw-bold">خبراؤنا</h3>

        <div class="row">

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">
                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/3.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Andrew B.Raupp
                                    <flag iso="us" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Founder & CEO</span> <br />
                                    STEAM.org Educational Research
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/2.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Mr. Brent Hutcheson
                                    <flag iso="za" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Director</span> <br />
                                    Hands on Technologies & Care for Education NPO
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/1.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Gareth James
                                    <flag iso="gb" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Strategic Programs and Partnerships Manager at WRO</span> <br />
                                    Member board of trustees at Groundwork
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/6.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Kobayashi Yasuhide
                                    <flag iso="jp" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>CEO</span> <br />
                                    <span>Afrel Co., Ltd </span> <br />
                                    Member of Information Processing Society of Jaban
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/5.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Danielle Pliska
                                    <flag iso="us" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Vice President</span> <br />
                                    FIRST
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/4.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Mr. Jonathan Hung
                                    <flag iso="sg" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Executive Chairman</span> <br />
                                    Singapore space and Technology Limited (SSTL)
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/7.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Lynette
                                    <flag iso="sg" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>Managing Director</span> <br />
                                    Space Faculty
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 expert">
                <div class="card">

                    <div class="row">

                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <img src="@/assets/images/our_experts/8.png" class="expertImg" alt="Expert Image">
                        </div>

                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div class="card-body">
                                <h6 class="card-title fw-bold">
                                    Johnson Jan
                                    <flag iso="cn" class="flagIco" />
                                </h6>
                                <p class="card-text">
                                    <span>CEO</span> <br />
                                    KK Intelligent Technology, Corp
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- *************************  -->

        </div>

    </div>
</template>

<script>
export default {
    name: 'OurExperts',
}
</script>

<style lang="scss" scoped>
.container {

    // For Top : 64 px + 40 px = 104px
    // For Bottom : 16px + 24px = 40px
    margin: 104px auto 16px auto;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    .expert {

        margin-bottom: 24px;
        direction: ltr;

        .card {

            border-radius: 8px;
            border: 1px solid $Primary;
            background-color: $Secondary;
            padding: 24px 16px;
            height: 200px;

            @include breakpoints(x-small) {
                height: 260px;
            }

            .expertImg {
                width: 72px;
                height: 72px;

                @include breakpoints(x-small) {
                    display: block;
                    margin-bottom: 10px;
                    /* margin-left: auto;
                    margin-right: auto; */
                }

            }

            .card-body {

                // padding: 0 0 0 20px;
                padding-top: 0;

                @include breakpoints(x-small) {
                    padding: 0;
                }

                @include breakpoints(medium) {
                    margin-left: 10px;
                }

                @include breakpoints(large) {
                    margin-left: 10px;
                }

                @include breakpoints(x-Large) {
                    margin-left: 10px;
                }


                h6 {
                    color: #8EC8C9;
                    border-bottom: 1px solid #8EC8C9;
                    padding-bottom: 8px !important;

                    background-image: url("@/assets/images/our_experts/Group.png");
                    background-size: 104px 16px;
                    background-repeat: no-repeat;
                    // background-position: 178px 13px;
                    background-position: 100% 110%;

                    @include breakpoints(medium) {
                        background-image: none;
                    }

                    @include breakpoints(x-Large) {
                        background-image: none;
                    }

                    .flagIco {
                        float: inline-end;
                    }
                }

                p {

                    color: $White;

                    span {
                        color: $gray;
                    }

                }

            }

        }

    }

}
</style>