<template>
    <OurProducts/>
    <Footer/>
</template>

<script>

import OurProducts from '@/components/our_products/OurProducts.vue';

export default {
    name: 'OurProductsView',
    components:{
        OurProducts,
    }
}
</script>

<style scoped lang="scss">

</style>