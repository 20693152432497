<template>
    <!-- ******************************  Start Slider ************************************* -->

    <div id="mySlider">

        <div id="sliderExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">

            <div class="carousel-indicators">
                <button type="button" data-bs-target="#sliderExampleIndicators" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#sliderExampleIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#sliderExampleIndicators" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            </div>

            <div class="carousel-inner">

                <div class="carousel-item active" data-bs-interval="1000">

                    <img src="@/assets/images/home/slider1.jpg" class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
                        alt="businessman-holding-arrow-graph-growth-business-financial-investment-strategy-planning">

                    <img src="@/assets/images/home/slider1_mobile.jpg" class="d-block d-sm-block d-md-none d-lg-none d-xl-none"
                        alt="businessman-holding-arrow-graph-growth-business-financial-investment-strategy-planning">

                </div>
                <div class="carousel-item" data-bs-interval="1000">

                    <img src="@/assets/images/home/slider2.jpg" class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
                        alt="businessman-holding-education-success-icon-education-success-concept-our-own-hands">

                    <img src="@/assets/images/home/slider2_mobile.jpg" class="d-block d-sm-block d-md-none d-lg-none d-xl-none"
                        alt="businessman-holding-education-success-icon-education-success-concept-our-own-hands">

                </div>
                <div class="carousel-item" data-bs-interval="1000">

                    <img src="@/assets/images/home/slider3.jpg" class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
                        alt="businessman-holding-light-bulb-analyzing-sales-data-economic-growth-graph-technology">

                    <img src="@/assets/images/home/slider3_mobile.jpg" class="d-block d-sm-block d-md-none d-lg-none d-xl-none"
                        alt="businessman-holding-light-bulb-analyzing-sales-data-economic-growth-graph-technology">
                        
                </div>

            </div>

        </div>

        <div class="content">
            <h5 class="mb-lg-4 mb-xl-4">طور مؤسستك بأحدث الأساليب التعليمية والتقنية</h5>

            <h3>
                <span class="fw-bold">قدراتك </span>
                شركة تعليمية تقنية تهدف إلى تنمية القدرات البشرية وتطوير المهارات بأساليب تعليمية حديثة.
            </h3>
        </div>

    </div>

    <!-- ******************************  End Slider ************************************* -->
</template>

<script>
export default {

    name: 'Slider',

}
</script>

<style lang="scss" scoped>
// -----------------  Slider ------------------------------ //
#mySlider {

    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    position: relative;

    .carousel-indicators {

        button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $gray;
        }

        button.active {
            width: 24px;
            height: 8px;
            border-radius: 16px;
            background-color: $Primary;
        }

    }

    .carousel-inner {
        .carousel-item {
            height: 810px;

            @include breakpoints(x-small) {
                height: 678px;
            }

            @include breakpoints(small) {
                height: 678px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .content {

        color: $White;
        position: absolute;
        z-index: 20;
        width: 510px;
        right: 120px;
        bottom: 250px;

        @include breakpoints(x-small) {
            width: 95%;
            right: 0;
            margin-left: 16px;
            margin-right: 16px;
            bottom: 56px;
        }

        @include breakpoints(small) {
            width: 95%;
            right: 0;
            margin-left: 16px;
            margin-right: 16px;
            bottom: 56px;
        }

        h5 {
            font-size: 18px;

            @include breakpoints(small) {
                font-size: 14px;
            }

            @include breakpoints(x-small) {
                font-size: 14px;
            }
        }

        h3 {
            font-size: 24px;

            @include breakpoints(x-small) {
                font-size: 16px;
            }

            @include breakpoints(small) {
                font-size: 16px;
            }
        }

        span {
            color: #68B6B7;
            font-size: 40px;

            @include breakpoints(x-small) {
                font-size: 20px;
            }

            @include breakpoints(small) {
                font-size: 20px;
            }
        }

    }

}
</style>