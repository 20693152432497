<!-- ############################## Start Template ########################################## -->

<template>
    <div class="container-fluid">

        <!-- ****************************** Start Company Products ************************************* -->
        <CompanyProducts/>
        <!-- ****************************** End Company Products ************************************* -->

        <!-- ****************************** Start Commercial Concession ************************************* -->
        <CommercialConcession/>
        <!-- ****************************** End Commercial Concession ************************************* -->

        <!-- ****************************** Start Commercial Concession Features ************************************* -->
        <CommercialConcessionFeatures/>
        <!-- ****************************** End Commercial Concession Features ************************************* -->

        <!-- ****************************** Start Commercial Concession Elements ************************************* -->
        <CommercialConcessionElements/>
        <!-- ****************************** End Commercial Concession Elements ************************************* -->

        <!-- ****************************** Start Professional Certificates ************************************* -->
        <ProfessionalCertificates/>
        <!-- ****************************** End Professional Certificates ************************************* -->

        <!-- ****************************** Start Certified Platform ************************************* -->
        <CertifiedPlatform/>
        <!-- ****************************** End Certified Platform ************************************* -->

        <!-- ****************************** Start Hackathons And Fourth Generation Skills Camps ************************************* -->
        <Camps/>
        <!-- ****************************** End Hackathons And Fourth Generation Skills Camps ************************************* -->

    </div>
</template>

<!-- ############################## End Template ########################################## -->

<!-- ############################## Start Script ########################################## -->

<script>

import CompanyProducts from '@/components/our_products/CompanyProducts.vue';
import CommercialConcession from '@/components/our_products/CommercialConcession.vue';
import CommercialConcessionFeatures from '@/components/our_products/CommercialConcessionFeatures.vue';
import CommercialConcessionElements from '@/components/our_products/CommercialConcessionElements.vue';
import ProfessionalCertificates from '@/components/our_products/ProfessionalCertificates.vue';
import CertifiedPlatform from '@/components/our_products/CertifiedPlatform.vue';
import Camps from '@/components/our_products/Camps.vue';

export default {

    name: 'OurProducts',

    components: {
        CompanyProducts,
        CommercialConcession,
        CommercialConcessionFeatures,
        CommercialConcessionElements,
        ProfessionalCertificates,
        CertifiedPlatform,
        Camps,
    },

}
</script>

<!-- ############################## End Script ########################################## -->

<!-- ############################### Start Style ######################################### -->

<style lang="scss" scoped>
.container-fluid {
    margin: 64px 0 0 0;
    padding: 0;
}

</style>

<!-- ############################### End Style ######################################### -->