<template>
    <!-- ******************************  Start Company Fields ************************************* -->

    <div id="company_fields">

        <!-- ******************************  Start Container ************************************* -->

        <div class="container">

            <h3 class="text-center fw-bold">مجالات الشركة</h3>

            <!-- ******************************  Top ************************************* -->

            <div class="main_div" v-if="topDiv">

                <p class="text-center mainParagraph">
                    نقدم خدمات الإستشارات والتدريب كما نعمل على زيادة كفاءة مخرجات التعليم وتوفير الأدوات الفنية
                    والعلمية والتشغيل بناء على سياسات وأدلة تشغيلية واضحة
                </p>

                <!-- https://getbootstrap.com/docs/5.3/components/card/ -->

                <div class="row row-cols-1 row-cols-md-5 g-4">

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/training.png" class="img-fluid" alt="Training">
                                <button type="button" class="btn text-white fw-bold d-block m-auto mt-4"
                                    @click="topDiv = false; trainingDiv = true;">التدريب</button>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/consulting.png" class="img-fluid" alt="Consulting">
                                <button type="button" class="btn text-white fw-bold d-block m-auto mt-4"
                                    @click="topDiv = false; consultingDiv = true;">الإستشارات</button>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/certification.png" class="img-fluid"
                                    alt="Certification">
                                <button type="button" class="btn text-white fw-bold d-block m-auto mt-4"
                                    @click="topDiv = false; certificationDiv = true;">الشهادات</button>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/preparation.png" class="img-fluid" alt="Preparation">
                                <button type="button" class="btn text-white fw-bold d-block m-auto mt-4"
                                    @click="topDiv = false; preparationDiv = true;">التجهيزات</button>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center">
                                <img src="@/assets/images/our_fields/employment.png" class="img-fluid" alt="Employment">
                                <button type="button" class="btn text-white fw-bold d-block m-auto mt-4"
                                    @click="topDiv = false; employmentDiv = true;">التشغيل</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- ******************************  Training ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" fieldDiv" v-if="trainingDiv">

                    <div class="row fieldRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="topDiv = true; trainingDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                            <div>

                                <h5 class="fw-bold">التدريب</h5>

                                <p class="mt-4">
                                    نعتمد بشكل أساسي على كفاءاتنا المتخصصة لنقل أفضل
                                    الممارسات العالمية وموائمتها محليا لضمان إحداث نقلة
                                    في تأهيل القدرات البشرية ونقل الخبرات واستدامتها.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                            <img src="@/assets/images/our_fields/trainingPic.jpg" alt="Training Picture" class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ******************************  Consulting ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" fieldDiv" v-if="consultingDiv">

                    <div class="row fieldRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="topDiv = true; consultingDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                            <div>

                                <h5 class="fw-bold">الإستشارات</h5>

                                <p class="mt-4">
                                    نقدم خدمات الاستشارات التعليمية بالشراكة مع أكبر بيوت الخبرة العالمية في تطوير بيئة
                                    التعليم والمعتمدة على المهارات التطبيقية لتتواكب مع متطلبات وظائف القرن الحادي
                                    والعشرين من خلال خبراء متخصصين في مجالات عديدة ومتقدمة.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                            <img src="@/assets/images/our_fields/consultingPic.jpg" alt="Consulting Picture"
                                class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ******************************  Certification ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" fieldDiv" v-if="certificationDiv">

                    <div class="row fieldRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="topDiv = true; certificationDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                            <div>

                                <h5 class="fw-bold">الشهادات الإحترافية</h5>

                                <p class="mt-4">
                                    نعمل على زيادة كفاءة مخرجات التعليم وربطها بمتطلبات سوق العمل من خلال تمثيل بيوت
                                    الخبرة العالمية بالإضافة إلى العمل مع بيوت الخبرة المحلية لبناء برامج احترافية وطنية
                                    في تأهيل القدرات البشرية.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                            <img src="@/assets/images/our_fields/certificationPic.jpg" alt="Certification Picture"
                                class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ******************************  Preparation ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" fieldDiv" v-if="preparationDiv">

                    <div class="row fieldRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="topDiv = true; preparationDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                            <div>

                                <h5 class="fw-bold">التجهيزات</h5>

                                <p class="mt-4">
                                    تعتمد منظومتنا على آلية مدروسة لعملية التجهيزات ومبنية على توفير الأدوات الفنية
                                    والعلمية المتوافقة مع عملية التشغيل من خلال التكامل بين التعليم النظري والتطبيق
                                    العملي لضمان كفاءة التشغيل والجودة.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                            <img src="@/assets/images/our_fields/preparationPic.jpg" alt="Preparation Picture"
                                class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

            <!-- ******************************  Employment ************************************* -->

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class=" fieldDiv" v-if="employmentDiv">

                    <div class="row fieldRow">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="topDiv = true; employmentDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldAbout">

                            <div>

                                <h5 class="fw-bold">التشغيل</h5>

                                <p class="mt-4">
                                    تتم عملية التشغيل بناء على سياسات وأدلة تشغيلة واضحة وفق معايير محددة وبتقارير دورية
                                    لزيادة كفاءة وجودة المخرجات بما يساعد على ضمان عملية التشغيل ونقل المعرفة.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 fieldImg">
                            <img src="@/assets/images/our_fields/employmentPic.jpg" alt="Employment Picture"
                                class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

        </div>

        <!-- ******************************  End Container ************************************* -->

    </div>

    <!-- ******************************  End Company Fields ************************************* -->
</template>

<script>
export default {

    name: 'CompanyFields',

    data() {
        return {

            // -----------------  Company Fields ------------------------------ //

            // https://www.youtube.com/watch?v=Y1qBr1VCm-A

            topDiv: true,

            trainingDiv: false,

            consultingDiv: false,

            certificationDiv: false,

            preparationDiv: false,

            employmentDiv: false,

        };
    },
}
</script>

<style lang="scss" scoped>
// -----------------  Company Fields ------------------------------ //

#company_fields {

    margin: 40px auto;
    // height: 460px;

    @include breakpoints(x-small) {
        height: fit-content;
    }

    @include breakpoints(small) {
        height: fit-content;
    }

    h3 {
        color: $heading;
        margin-bottom: 24px;
    }

    // ------------- Top Div ------------- //

    .main_div {

        .mainParagraph {
            color: $homeParagraph;
            margin-bottom: 40px;
        }

        .card {

            background-color: $homeBG;
            border: none;

            .card-body {

                img {
                    width: 80px;
                    height: 80px;
                }

                img:hover {
                    -webkit-animation: blinker 1s linear infinite;
                    -moz-animation: blinker 1s linear infinite;
                    -ms-animation: blinker 1s linear infinite;
                    -o-animation: blinker 1s linear infinite;
                    animation: blinker 1s linear infinite;
                }

                button {
                    padding: 6px 8px;
                    border-radius: 4px;
                    background-color: $Primary;
                    box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);
                }

            }

        }

    }

    // ------------- Description Div ------------- //

    .fieldDiv {

        position: relative;

        .fieldRow {

            margin: 0;

            .btn_box {

                position: absolute;
                left: 0px;
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                padding: 0;

                button {

                    padding: 0;

                    svg {
                        color: $Primary;
                    }

                }

            }

            .fieldAbout {

                display: table;
                margin-top: 50px;
                padding-right: 0;

                div {

                    display: table-cell;
                    vertical-align: middle;
                    color: $heading;

                    h5 {
                        @include breakpoints(x-small) {
                            text-align: center;
                        }

                        @include breakpoints(small) {
                            text-align: center;
                        }
                    }
                }

            }

            // https://dev.to/ellen_dev/two-ways-to-achieve-an-image-colour-overlay-with-css-eio

            .fieldImg {

                margin-top: 50px;
                overflow: hidden;
                background-color: $Primary;
                padding: 0;
                border-radius: 8px;
                height: 328px;

                @include breakpoints(x-small) {
                    margin-top: 10px;
                }

                @include breakpoints(small) {
                    margin-top: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.75;
                }

            }

        }

    }

}

// For Transition
.fade-enter-active {
    transition: opacity 1.5s ease;
}

.fade-leave-active {
    opacity: 0;
}

.fade-enter-from {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}
// For Animation
@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-moz-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-o-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
</style>