// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OurFieldsView from '../views/OurFieldsView.vue'
import OurProductsView from '../views/OurProductsView.vue'
import AbilitiesPicturesView from '../views/AbilitiesPicturesView.vue'
import OurExpertsView from '../views/OurExpertsView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/our-fields',
    name: 'OurFields',
    component: OurFieldsView
  },
  {
    path: '/our-products',
    name: 'OurProducts',
    component: OurProductsView
  },
  {
    path: '/abilities-pictures',
    name: 'AbilitiesPictures',
    component: AbilitiesPicturesView
  },
  {
    path: '/our-experts',
    name: 'OurExperts',
    component: OurExpertsView
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
});

/* router.beforeEach((to,from,next)=>{

  if (to.name != null){
    document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  } else{
    document.title = `${process.env.VUE_APP_TITLE}`;
  }
  
  next();

}); */

export default router
