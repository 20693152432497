<template>
    <div id="customers">

        <!-- ******************************  Start Container ************************************* -->

        <div class="container">

            <h3 class="text-center fw-bold">عملاؤنا</h3>

            <div class="row h-100">

                <!-- Customer Slider -->

                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 rounded my-auto" id="customer_slider">

                    <!-- For Large Screen -->

                    <div class="large_screen d-none d-sm-none d-md-block d-lg-block d-xl-block">

                        <div id="customerExampleIndicators" class="carousel slide" data-bs-ride="carousel"
                            data-bs-keyboard="true">

                            <div class="carousel-inner">

                                <div class="carousel-item active" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_1.png" alt="الشركة الوطنية">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_2.png" alt="مدارس الرياض">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_3.png" alt="الحصان للتعليم">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_4.png" alt="مدارس الاندلس">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_5.png" alt="الهيئة الملكية">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_6.png" alt="اجيالنا">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_7.png" alt="عطاء">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_8.png" alt="ابن راشد">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_9.png" alt="مدارس الاندلس">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_10.png" alt="سعود">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_11.png" alt="العلوم التقنية">
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_12.png" alt="المجمعة">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <button class="carousel-control-prev" type="button" data-bs-target="#customerExampleIndicators"
                                data-bs-slide="prev">
                                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                            </button>

                            <button class="carousel-control-next" type="button" data-bs-target="#customerExampleIndicators"
                                data-bs-slide="next">
                                <font-awesome-icon :icon="['fas', 'arrow-right']" />
                            </button>

                        </div>

                    </div>

                    <!-- For Small Screen -->

                    <div class="small_screen d-block d-sm-block d-md-none d-lg-none d-xl-none">

                        <div id="customerSmallExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                            <div class="carousel-inner">

                                <div class="carousel-item active" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_1.png" alt="الشركة الوطنية">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_2.png" alt="مدارس الرياض">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_3.png" alt="الحصان للتعليم">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_4.png" alt="مدارس الاندلس">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_5.png" alt="الهيئة الملكية">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_6.png" alt="اجيالنا">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_7.png" alt="عطاء">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_8.png" alt="ابن راشد">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_9.png" alt="مدارس الاندلس">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_10.png" alt="سعود">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_11.png" alt="العلوم التقنية">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item" data-bs-interval="5000">
                                    <div class="card-wrapper animate__animated animate__backInDown animate__delay-0.9s animate__slow">
                                        <div class="card">
                                            <div class="image-wrapper">
                                                <img src="@/assets/images/home/our_customers_12.png" alt="المجمعة">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="d-flex justify-content-center">
                                <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                                <!-- Carousel controls -->
                                <button class="carousel-control-next" type="button"
                                    data-bs-target="#customerSmallExampleIndicators" data-bs-slide="next">
                                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                                </button>
                                <button class="carousel-control-prev" type="button"
                                    data-bs-target="#customerSmallExampleIndicators" data-bs-slide="prev">
                                    <font-awesome-icon :icon="['fas', 'arrow-left']" />
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

                <!-- Customer Content -->

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 my-auto" id="customer_content">

                    <div class="customer_content">

                        <div class="row text-center fw-bold">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-8">
                                <h5>+260</h5>
                                <p>مدرسة حول المملكة</p>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'fa-school-flag']" class="fa-2xl" />
                                </span>
                            </div>
                        </div>

                    </div>

                    <div class="customer_content">

                        <div class="row text-center fw-bold">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-8">
                                <h5>+17 ألف</h5>
                                <p>مستفيد حول المملكة</p>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'fa-users']" class="fa-2xl" />
                                </span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <!-- ******************************  End Container ************************************* -->

    </div>
</template>

<script>
export default {
    name: 'Customers',
}
</script>

<style lang="scss" scoped>
// ----------------- Customers ------------------------------ //
#customers {

    background-color: $homeBG;
    padding-top: 40px;
    padding-bottom: 40px;
    // border-top: 1px solid $Secondary;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    // Customer Slider

    // For Large Screen

    @media screen and (min-width: 768px) {

        #customer_slider {

            background-color: $White;
            padding-left: 24px;
            padding-right: 24px;

            @include breakpoints(medium) {
                order: 2;
            }

            @include breakpoints(large) {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            @include breakpoints(x-Large) {
                padding-top: 24px;
                padding-bottom: 24px;
            }

            .large_screen {

                .carousel {

                    .carousel-inner {

                        padding-left: 24px;
                        padding-right: 24px;

                        .carousel-item {

                            transition: transform 2.5s ease-in-out;

                            .card-wrapper {

                                display: flex;

                                .card {
                                    width: calc(100%/3);
                                    border: none;

                                    .image-wrapper {

                                        height: 188px;
                                        padding: 10px;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: contain;
                                        }

                                        img:hover {
                                            -webkit-animation: blinker 1s linear infinite;
                                            -moz-animation: blinker 1s linear infinite;
                                            -ms-animation: blinker 1s linear infinite;
                                            -o-animation: blinker 1s linear infinite;
                                            animation: blinker 1s linear infinite;
                                        }

                                    }
                                }

                            }

                        }
                    }

                    .carousel-control-prev,
                    .carousel-control-next {
                        width: fit-content;
                    }

                    .carousel-control-prev svg,
                    .carousel-control-next svg {
                        // color: $Secondary;
                        color: $Primary;
                        width: 24px;
                        height: 24px;
                    }

                    /* .carousel-control-prev:active svg,
                    .carousel-control-next:active svg {
                        color: $Primary;
                    } */

                }

            }
        }

    }

    // For Small Screen

    @media screen and (max-width: 768px) {

        #customer_slider {

            @include breakpoints(x-small) {
                order: 2;
            }

            @include breakpoints(small) {
                order: 2;
            }

            .small_screen .carousel {

                .carousel-inner .carousel-item {

                    transition: transform 2.5s ease-in-out;

                    .card-wrapper {

                        .card {

                            box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);

                            .image-wrapper {

                                height: 188px;
                                margin: 0 auto;
                                padding: 25px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }

                                img:hover {
                                    -webkit-animation: blinker 1s linear infinite;
                                    -moz-animation: blinker 1s linear infinite;
                                    -ms-animation: blinker 1s linear infinite;
                                    -o-animation: blinker 1s linear infinite;
                                    animation: blinker 1s linear infinite;
                                }

                            }

                        }

                    }

                }

                .carousel-control-prev,
                .carousel-control-next {
                    position: static;
                    padding-top: 16px;

                }

                .carousel-control-prev svg,
                .carousel-control-next svg {
                    color: $Primary;
                    width: 24px;
                    height: 24px;
                }

            }

        }


    }

    // For Animation
    @-webkit-keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    @-moz-keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    @-o-keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    // Customer Content

    #customer_content {

        padding-right: 40px;

        @include breakpoints(x-small) {
            order: 1;
        }

        @include breakpoints(small) {
            order: 1;
        }

        @include breakpoints(medium) {
            order: 1;
        }

        .customer_content {

            padding-bottom: 16px;

            h5 {
                font-size: 40px;
            }

            p {
                font-size: 14px;
            }

            span svg {
                width: 80px;
                height: 80px;
            }

        }

        .customer_content:not(:first-child) {
            border-top: 1px solid $gray;
            padding-top: 16px;
        }
    }

}
</style>