<template>
    <!-- ****************************** Start Hackathons And Fourth Generation Skills Camps ************************************* -->

    <div id="camps">

        <div class="container">

            <div class="row">

                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 camps_text">

                    <div>

                        <h3 class="fw-bold">هاكاثونات ومعسكرات مهارات الجيل الصناعي الرابع</h3>

                        <p class="text-justify">نربط بين احتياجات سوق العمل ومهارات الباحثين عن الوظائف من خلال برامج
                            تدريبية متقدمة بالإضافة إلى هاكاثونات تقنية متخصصة ومعسكرات تدريبية مكثفة نعزز الإبداع
                            والابتكار
                            ونطمح لتدريب العقول البشرية على إيجاد أفضل الحلول وإنشاء مشاريع مبتكرة في كل مجالات
                            المستقبل 
                        </p>

                    </div>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 camps_img">

                    <div class="image_wrapper rounded">
                        <img src="@/assets/images/our_products/father-sons-making-robot.jpg" alt="" class="rounded">
                    </div>

                </div>

            </div>

        </div>

    </div>

    <!-- ****************************** End Hackathons And Fourth Generation Skills Camps ************************************* -->
</template>

<script>
export default {
    name: 'Camps',
}
</script>

<style lang="scss" scoped>
// ------------------ Hackathons And Fourth Generation Skills Camps ----------------------------- //

#camps {

    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $homeBG;
    text-align: right;

    .camps_text {

        display: table;

        div {

            display: table-cell;
            vertical-align: middle;

            h3 {

                background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
                // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -o-background-clip: text;
                -ms-background-clip: text;
                background-clip: text;
                color: transparent;
                margin-bottom: 16px;

                @include breakpoints(x-small) {
                    text-align: center;
                }

            }

            p {
                color: $heading;
            }

        }

    }

    .camps_img {

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 36px;

        .image_wrapper {

            width: 423px;
            height: 276px;
            position: relative;
            background-color: $Secondary;

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -36px;
                left: 40px;

                @include breakpoints(x-small) {
                    left: 0;
                }

            }

        }

    }

}
</style>