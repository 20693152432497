<template>
    <!-- ****************************** Start Professional Certificates ************************************* -->

    <div id="certificates">

        <div class="container">

            <h3 class="fw-bold">الشهادات الإحترافية</h3>

            <p class="text-justify">نعمل بالشراكة مع بيوت الخبرة العالمية لتمثيلها محليا بالإضافة إلى امتلاكنا برامج
                احترافية متخصصة في مختلف
                القطاعات والمجالات ومتوافقة مع متطلبات سوق العمل المحلي، بهدف تعزيز المهارات والخبرات العملية. مع ضمان
                اكتساب الممارسين المهارات اللازمة في المجال طبقا لأعلى المعايير وأفضل الممارسات العالمية.</p>

            <!-- For Large Screen -->

            <div class="large_screen d-none d-sm-none d-md-none d-lg-block d-xl-block">

                <!-- https://getbootstrap.com/docs/5.3/components/card/ -->

                <!-- row-cols-1 row-cols-md-3 -->

                <div class="row row-cols-lg-5 g-4">

                    <div class="col">
                        <div class="card">
                            <div class="image-wrapper">
                                <img src="@/assets/images/our_products/Cer1.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-img-overlay">
                                <img src="@/assets/images/our_products/cer1_icon.svg" alt="">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title text-center fw-bold">تعليمية</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="image-wrapper">
                                <img src="@/assets/images/our_products/Cer2.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-img-overlay">
                                <img src="@/assets/images/our_products/cer2_icon.svg" alt="">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title text-center fw-bold">صحية</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="image-wrapper">
                                <img src="@/assets/images/our_products/Cer3.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-img-overlay">
                                <img src="@/assets/images/our_products/cer3_icon.svg" alt="">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title text-center fw-bold">إدارية</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="image-wrapper">
                                <img src="@/assets/images/our_products/Cer4.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-img-overlay">
                                <img src="@/assets/images/our_products/cer4_icon.svg" alt="">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title text-center fw-bold">تقنية</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <div class="image-wrapper">
                                <img src="@/assets/images/our_products/Cer5.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-img-overlay">
                                <img src="@/assets/images/our_products/cer5_icon.svg" alt="">
                            </div>
                            <div class="card-body ">
                                <h5 class="card-title text-center fw-bold">مالية</h5>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- For Medium & Small Screen -->

            <div class="small_screen d-block d-sm-block d-md-block d-lg-none d-xl-none">

                <div id="CertificateSmallExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">

                        <div class="carousel-item active">
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/our_products/Cer1.png" class="card-img-top" alt="...">
                                    </div>
                                    <div class="card-img-overlay">
                                        <img src="@/assets/images/our_products/cer1_icon.svg" alt="">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title text-center fw-bold">تعليمية</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/our_products/Cer2.png" class="card-img-top" alt="...">
                                    </div>
                                    <div class="card-img-overlay">
                                        <img src="@/assets/images/our_products/cer2_icon.svg" alt="">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title text-center fw-bold">صحية</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/our_products/Cer3.png" class="card-img-top" alt="...">
                                    </div>
                                    <div class="card-img-overlay">
                                        <img src="@/assets/images/our_products/cer3_icon.svg" alt="">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title text-center fw-bold">إدارية</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/our_products/Cer4.png" class="card-img-top" alt="...">
                                    </div>
                                    <div class="card-img-overlay">
                                        <img src="@/assets/images/our_products/cer4_icon.svg" alt="">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title text-center fw-bold">تقنية</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/our_products/Cer5.png" class="card-img-top" alt="...">
                                    </div>
                                    <div class="card-img-overlay">
                                        <img src="@/assets/images/our_products/cer5_icon.svg" alt="">
                                    </div>
                                    <div class="card-body ">
                                        <h5 class="card-title text-center fw-bold">مالية</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex justify-content-center">

                        <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                        <!-- Carousel controls -->
                        <button class="carousel-control-next rounded" type="button"
                            data-bs-target="#CertificateSmallExampleIndicators" data-bs-slide="next">
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                        <button class="carousel-control-prev rounded" type="button"
                            data-bs-target="#CertificateSmallExampleIndicators" data-bs-slide="prev">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <!-- ****************************** End Professional Certificates ************************************* -->
</template>

<script>
export default {
    name: 'ProfessionalCertificates',
}
</script>

<style lang="scss" scoped>
// ------------------ Professional Certificates ----------------------------- //

#certificates {

    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $homeBG;
    text-align: right;

    h3 {
        background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
        // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -o-background-clip: text;
        -ms-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-bottom: 16px;

        @include breakpoints(x-small) {
            text-align: center;
        }
    }

    p {
        color: $heading;
        margin-bottom: 40px;
    }

    // For All Screen

    .card {

        background: none;
        border: none;

        .image-wrapper {
            position: relative;
        }

        .card-body {

            h5 {
                background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
                // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -o-background-clip: text;
                -ms-background-clip: text;
                background-clip: text;
                color: transparent;
            }

        }

    }

    // For Large Screen

    .large_screen .card {

        .card-img-overlay img {
            width: 24px;
            height: 24px;
            // margin-top: 80px;
            // margin-right: 45px;
            position: absolute;
            top: 55%;
            left: 69%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            @include breakpoints(large) {
                top: 50%;
            }

        }

    }

    // For Medium & Small Screen

    .small_screen {
        .carousel {
            .carousel-inner .carousel-item {

                transition: transform 2.5s ease-in-out;

                .card {

                    margin-left: 20px;
                    margin-right: 20px;

                    .card-img-overlay img {

                        width: 45px;
                        height: 45px;
                        position: absolute;
                        top: 68%;
                        left: 69%;
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);

                        @include breakpoints(x-small) {
                            width: 30px;
                            height: 30px;
                            top: 65%;
                        }

                    }

                }

            }

            .carousel-control-prev,
            .carousel-control-next {
                position: static;
                padding-top: 16px;

            }

            .carousel-control-prev svg,
            .carousel-control-next svg {
                color: $Primary;
                width: 24px;
                height: 24px;
            }

        }

    }

}
</style>