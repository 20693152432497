<template>
    <OurExperts/>
    <Footer/>
</template>

<script>

import OurExperts from '@/components/our_experts/OurExperts.vue';

export default {

    name: 'OurExpertsView',

    components:{
        OurExperts,
    }

}
</script>

<style scoped lang="scss">

</style>