<template>
    <!-- ****************************** Start Commercial Concession ************************************* -->

    <!-- Commercial Concession -->

    <div id="commercial_concession">

        <!-- Main Div -->

        <div class="main_div" v-if="mainDiv">

            <div class="row m-0">

                <div class="col-sm-2 col-md-2 col-lg-4 col-xl-4">

                    <!-- style="right: 0;" -->
                    <div class="image bg">
                        <!-- <img src="@/assets/images/our_products/commercial_concession_bg.svg"
                            alt="Commercial Concession Background"> -->
                        <img src="@/assets/images/our_products/commercial_concession_bg.png"
                            alt="Commercial Concession Background">
                    </div>

                    <div class="image person">
                        <img src="@/assets/images/our_products/commercial_concession_person.png"
                            alt="Commercial Concession Person">
                    </div>

                </div>

                <div class="col-sm-10 col-md-10 col-lg-8 col-xl-8 commercial_concession_text">

                    <div class="text-center">

                        <h3 class="fw-bold">ما هو الإمتياز التجاري STEAM class؟</h3>

                        <button type="button" class="btn mt-2" @click="mainDiv = false; descriptionDiv = true;">اعرف
                            المزيد</button>

                    </div>

                </div>

            </div>

        </div>

        <!-- Description Div -->

        <div class="container">

            <!-- transition : Small characters -->
            <transition name="fade">

                <div class="description_div" v-if="descriptionDiv">

                    <div class="row description_row">

                        <!-- https://www.youtube.com/watch?v=HspBC6aMzPk -->

                        <div class="btn_box">
                            <button type="button" class="btn text-white fw-bold d-block"
                                @click="mainDiv = true; descriptionDiv = false;">
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" class="fa-2xl" />
                            </button>
                        </div>

                        <!-- https://www.w3docs.com/snippets/css/how-to-vertically-center-text-with-css.html -->

                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 description_about">

                            <div>

                                <h5 class="fw-bold">الإمتياز التجاري</h5>

                                <p class="mt-4">يعمل نظام تطبيق STEAM class على تأهيل الطلاب للالتحاق بوظائف المستقبل
                                    التى تحتاج المهارات والخبرة العملية في مجالات الهندسة، والتكنولوجيا، والعلوم،
                                    والفنون والرياضيات.
                                    <br />
                                    فطلاب STEAM مؤهلين بالكامل للالتحاق بوظائف المستقبل المبنية على
                                    مجالات الجيل الصناعي الرابع.
                                </p>

                            </div>

                        </div>

                        <!-- https://www.youtube.com/watch?v=xtvlivrfMB0 -->

                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 description_img">
                            <img src="@/assets/images/our_products/commercial_concession_div.png" alt="Training Picture"
                                class="rounded">
                        </div>

                    </div>

                </div>

            </transition>

        </div>

    </div>

    <!-- ****************************** End Commercial Concession ************************************* -->
</template>

<script>
export default {

    name: 'CommercialConcession',

    data() {
        return {

            // -----------------  Commercial Concession ------------------------------ //

            mainDiv: true,

            descriptionDiv: false,

        }
    }

}
</script>

<style lang="scss" scoped>
// ------------------ Commercial Concession ----------------------------- //

#commercial_concession {

    // Main Div

    .main_div {

        width: 100%;
        height: 540px;
        position: relative;

        @include breakpoints(x-small) {
            height: 400px;
        }

        @include breakpoints(small) {
            height: 400px;
        }

        .image {

            position: absolute;

            img {
                width: 100%;
                height: 100%;
            }

        }

        .bg {
            // width: 929px;
            // height: 626px;
            right: 0 !important;
            top: 0;

            @include breakpoints(x-small) {
                width: 375px;
                height: 252.695px;
                right: -30px;
            }

            @include breakpoints(small) {
                width: 375px;
                height: 252.695px;
                right: -30px;
            }
        }

        .person {

            width: 335px;
            height: 500px;
            right: 120px;
            bottom: 0;

            @include breakpoints(x-small) {
                display: none;
            }

            @include breakpoints(small) {
                display: none;
            }

            @include breakpoints(medium) {
                display: none;
            }

        }

        .commercial_concession_text {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 540px;

            @include breakpoints(small) {
                height: 400px;
            }

            @include breakpoints(x-small) {
                height: 400px;
                position: absolute;
                top: 20%;
            }

            h3 {
                background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);
                // https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -o-background-clip: text;
                -ms-background-clip: text;
                background-clip: text;
                color: transparent;

                // font-size: 40px;
                font-size: xxx-large;

                @include breakpoints(x-small) {
                    font-size: initial;
                }

                @include breakpoints(small) {
                    font-size: large;
                }

                @include breakpoints(medium) {
                    font-size: x-large;
                }

                @include breakpoints(large) {
                    font-size: xx-large;
                }

                @include breakpoints(x-Large) {
                    font-size: xx-large;
                }

            }

            button {
                background-color: $Primary;
                color: $White;
            }

        }

    }

    // Description Div

    .description_div {

        position: relative;

        .description_row {

            .btn_box {

                position: absolute;
                left: 0px;
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;

                button svg {
                    color: $Primary;
                }

            }

            .description_about {

                display: table;
                margin-top: 20px;

                @include breakpoints(x-small) {
                    order: 2
                }

                @include breakpoints(small) {
                    order: 2
                }

                @include breakpoints(medium) {
                    order: 2
                }

                div {

                    display: table-cell;
                    vertical-align: middle;
                    color: $heading;

                    h5 {
                        @include breakpoints(x-small) {
                            text-align: center;
                        }

                        @include breakpoints(small) {
                            text-align: center;
                        }

                        @include breakpoints(medium) {
                            text-align: center;
                        }
                    }

                }

            }

            // https://dev.to/ellen_dev/two-ways-to-achieve-an-image-colour-overlay-with-css-eio

            .description_img {

                margin-top: 20px;
                padding: 0;
                height: 460px;

                @include breakpoints(x-small) {
                    margin-top: 10px;
                    order: 1
                }

                @include breakpoints(small) {
                    margin-top: 10px;
                    order: 1
                }

                @include breakpoints(medium) {
                    margin-top: 10px;
                    order: 1;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }

        }

    }

    // For Transition

    .fade-enter-active {
        transition: opacity 1.5s ease;
    }

    .fade-leave-active {
        opacity: 0;
    }

    .fade-enter-from {
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
    }

}
</style>