<template>
    <!-- ****************************** Start Commercial Concession Features ************************************* -->

    <div id="features">

        <div class="container">

            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 feature_img">

                    <div class="image_wrapper">
                        <img src="@/assets/images/our_products/commercial_concession_adv.png" alt="Feature Image">
                    </div>

                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 feature_text">

                    <h3 class="fw-bold">مميزات الإمتياز التجاري</h3>

                    <p class="text-justify">
                        <!-- <font-awesome-icon :icon="['fas', 'fa-square-check']" /> -->
                        <img src="@/assets/images/our_products/icon_one.svg" alt="">
                        أدلة تشغيل واضحة للمساعدة في كفاءة التشغيل
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_two.svg" alt="">
                        نموذج عمل مثبت
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_three.svg" alt="">
                        عائد مرتفع على الاستثمار
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_four.svg" alt="">
                        برنامج تدريبي مستمر لجميع الموظفين
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_five.svg" alt="">
                        ترخيص لتشغيل علامة STEAM Class
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_six.svg" alt="">
                        عملاء ودخل مضمون
                    </p>

                    <p class="text-justify">
                        <img src="@/assets/images/our_products/icon_seven.svg" alt="">
                        <!-- <i class="fa-solid fa-laptop-code"></i> -->
                        بوابة ومنصة تشغيلية كاملة لتشغيل STEAM Class
                    </p>

                    <p class="text-justify">
                        <!-- <font-awesome-icon :icon="['fas', 'fa-gear']" /> -->
                        <img src="@/assets/images/our_products/icon_eight.svg" alt="">
                        المساعدة والدعم المستمر
                    </p>

                </div>

            </div>

        </div>

    </div>

    <!-- ****************************** End Commercial Concession Features ************************************* -->
</template>

<script>
export default {
    name: 'CommercialConcessionFeatures',
}
</script>

<style lang="scss" scoped>
// ------------------ Commercial Concession Features ----------------------------- //

#features {

    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $productColor;
    color: $White;
    text-align: right;

    .feature_img {

        margin: auto;
        position: relative;

        @include breakpoints(x-small) {
            order: 2;
        }

        @include breakpoints(small) {
            order: 2;
        }

        .image_wrapper {

            width: 356px;
            height: 358px;
            margin: 0;

            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            @include breakpoints(x-small) {
                position: initial;
                top: 0;
                left: 0;
                -ms-transform: none;
                transform: none;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }

            @include breakpoints(small) {
                width: 500px;
                position: initial;
                top: 0;
                left: 0;
                -ms-transform: none;
                transform: none;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }

    }

    .feature_text {

        @include breakpoints(x-small) {
            order: 1;
        }

        @include breakpoints(small) {
            order: 1;
        }

        h3 {

            margin-bottom: 16px;

            @include breakpoints(x-small) {
                text-align: center;
            }

            @include breakpoints(small) {
                text-align: center;
            }

        }

        p {

            @include breakpoints(x-small) {
                margin-right: 10%;
            }

            @include breakpoints(small) {
                margin-right: 20%;
            }

            svg,
            img {

                width: 24px;
                height: 24px;
                margin-left: 8px;

            }

        }

    }

}
</style>