<template>

    <!-- https://mdbootstrap.com/docs/standard/navigation/footer/ -->

    <!-- Footer -->

    <footer>

        <div class="container">

            <!-- Grid row -->

            <div class="row">

                <!-- Grid column -->
                <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-auto" id="about-us">
                    <!-- d-sm-none .d-md-block .d-lg-block d-xl-block d-xxl-block -->
                    <h6 class="fw-bold mb-3">نبذة عننا</h6>
                    <p class="mb-3">قدراتك شركة تعليمية تقنية تهدف إلى تنمية القدرات البشرية وتطوير المهارات بأساليب تعليمية
                        حديثة</p>
                    <img src="@/assets/images/logo2.png" alt="footer_logo" id="logoImg" class="mb-sm-2 mb-md-2">
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mx-auto">
                    <!-- Links -->
                    <h6 class="fw-bold mb-3">
                        تواصل معنا
                    </h6>
                    <p>
                        <img src="@/assets/images/footer/location.png" alt="Location" class="contactImg ms-2">
                        السعودية - الإمارات - مصر
                    </p>
                    <p>
                        <img src="@/assets/images/footer/Contact.png" alt="Contact" class="contactImg ms-2">
                        <span>966531577796+</span>
                    </p>
                    <p>
                        <img src="@/assets/images/footer/mail.png" alt="Mail" class="contactImg ms-2">
                        <a href="https://portal.thaka.sa/ar" class="text-reset">info@thaka.net</a>
                    </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mx-auto">
                    <!-- Links -->
                    <h6 class="fw-bold mb-3">
                        روابط هامة
                    </h6>
                    <p>
                        <a href="/our-fields" class="text-reset">مجالاتنا</a>
                    </p>
                    <p>
                        <a href="/our-products" class="text-reset">منتجاتنا</a>
                    </p>
                    <p>
                        <a href="/#partners" class="text-reset">شركاء النجاح</a>
                    </p>
                    <p>
                        <a href="/#customers" class="text-reset">عملاؤنا</a>
                    </p>
                    <p>
                        <a href="/our-experts" class="text-reset">خبراؤنا</a>
                    </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-auto">
                    <!-- Links -->
                    <h6 class="fw-bold mb-3">آخر الأخبار</h6>
                    <p>ادخل بريدك الإلكتروني ليصلك كل جديد</p>
                    <!-- Email input -->
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="البريد الإلكتروني" aria-label="Email"
                            aria-describedby="basic-addon1" />
                        <span class="input-group-text" id="basic-addon1">
                            <!-- <i class="fa-solid fa-paper-plane fa-rotate-270"></i> -->
                            <!-- <font-awesome-icon icon="fa-paper-plane" /> -->
                            <font-awesome-icon :icon="['fas', 'fa-paper-plane']" class="inputIco" />
                        </span>
                    </div>
                    <!-- Social Links -->
                    <div class="mb-sm-3 mb-md-3" id="social">
                        <a href="https://www.facebook.com/" class="me-4 text-reset">
                            <!-- <i class="fa-brands fa-facebook-f"></i> -->
                            <font-awesome-icon :icon="['fab', 'facebook-f']" />
                        </a>
                        <a href="https://twitter.com/" class="me-4 text-reset">
                            <!-- <i class="fa-brands fa-x-twitter"></i> -->
                            <font-awesome-icon :icon="['fab', 'x-twitter']" />
                        </a>
                        <a href="https://www.instagram.com/" class="me-4 text-reset">
                            <!-- <i class="fa-brands fa-instagram"></i> -->
                            <font-awesome-icon :icon="['fab', 'instagram']" />
                        </a>
                        <a href="https://www.linkedin.com/" class="me-4 text-reset">
                            <!-- <i class="fa-brands fa-linkedin-in"></i> -->
                            <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                        </a>
                    </div>
                </div>
                <!-- Grid column -->

            </div>

            <!-- Grid row -->

        </div>

        <!-- Copyright -->
        <div class="text-center pt-3 pb-3" id="copyright">
            جميع الحقوق محفوظة لدى قدراتك التعليمية
            &copy;
            <a class="text-reset fw-bold" href="https://mdbootstrap.com/">{{ new Date().getFullYear() }}</a>
        </div>

        <!-- Copyright -->

    </footer>

    <!-- Footer -->
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {

    // https://caniuse.com/?search=linear-gradient
    background: linear-gradient(180deg, $Primary -19.96%, $Secondary 66.05%);

    color: $White;

    a {
        &:hover {
            color: $Primary !important;
        }
    }

    .row {

        padding-top: 40px;

        border-bottom: 1px solid $gray;

        p {
            font-size: 14px;
        }

        #about-us {

            #logoImg {

                width: 134px;
                height: 56px;

                @include breakpoints(x-small) {
                    margin-bottom: 8px;
                }

            }

            @include breakpoints(x-small) {

                display: flex;

                flex-direction: column;

                h6 {
                    display: none;
                }

                p {
                    order: 2;
                }

                #logoImg {
                    order: 1;
                }

            }

            @include breakpoints(small) {

                display: flex;

                flex-direction: column;

                h6 {
                    display: none;
                }

                p {
                    order: 2;
                }

                #logoImg {
                    order: 1;
                }

            }

            @include breakpoints(medium) {

                display: flex;

                flex-direction: column;

                h6 {
                    display: none;
                }

                p {
                    order: 2;
                }

                #logoImg {
                    order: 1;
                }

            }

        }

        .contactImg {
            width: 24px;
            height: 24px;
        }

        .input-group {

            input {
                border-radius: 0 4px 4px 0 !important;
            }

            span {
                background: $Primary;
                color: $White;
                border-radius: 4px 0 0 4px !important;
            }

            input::placeholder {
                font-size: 12px;
            }

            input:focus,
            input:focus+span {
                border-color: $gray !important;
                box-shadow: 0 0 8px 0 $gray;
            }

            input:focus::placeholder {
                color: $Primary;
            }

            .inputIco {
                // CSS Vendor Prefixes
                -webkit-transform: rotate(270deg);
                -moz-transform: rotate(270deg);
                -o-transform: rotate(270deg);
                -ms-transform: rotate(270deg);
                transform: rotate(270deg);
            }

        }

        #social {
            @include breakpoints(x-small) {
                margin-bottom: 16px;
            }
        }

    }

    #copyright {
        font-size: 12px;
    }

}
</style>
