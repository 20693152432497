<template>
    <AbilitiesPictures/>
    <Footer/>
</template>

<script>

import AbilitiesPictures from '@/components/abilities_pictures/AbilitiesPictures.vue'

export default {

    name: 'AbilitiesPicturesView',

    components:{
        AbilitiesPictures,
    }

}
</script>

<style scoped lang="scss">

</style>