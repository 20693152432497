<template>
    <div id="partners">

        <!-- ******************************  Start Container ************************************* -->

        <div class="container">

            <h3 class="text-center fw-bold">شركاء النجاح</h3>

            <!-- https://www.youtube.com/watch?v=kHPm_AlxP7U -->
            <!-- https://www.youtube.com/watch?v=nS_Ht0lT-uQ -->

            <!-- For Large Screen -->

            <div class="large_screen d-none d-sm-none d-md-block d-lg-block d-xl-block">

                <div id="partnerExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-keyboard="true">

                    <div class="carousel-inner">

                        <div class="carousel-item active" data-bs-interval="10000">
                            <!-- animate__animated animate__slideInDown animate__delay-2s animate__slow -->
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_1.png" alt="وزارة التعليم">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_2.png" alt="الامير سطام">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_3.png" alt="مكتب التربية">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_4.png" alt="واحة الملك">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_5.png" alt="مدينة الملك">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_6.png" alt="واحة الملك 2">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_7.png" alt="STEM">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_8.png" alt="WRO">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_9.png" alt="Be Steam">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_10.png" alt="SME Bank">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_11.png" alt="منشات">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_12.png" alt="الهيئة الملكية">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex justify-content-center">
                        <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                        <!-- Carousel controls -->
                        <button class="carousel-control-next rounded" type="button"
                            data-bs-target="#partnerExampleIndicators" data-bs-slide="next">
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                        <button class="carousel-control-prev rounded" type="button"
                            data-bs-target="#partnerExampleIndicators" data-bs-slide="prev">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                    </div>

                </div>

            </div>

            <!-- For Small Screen -->

            <div class="small_screen d-block d-sm-block d-md-none d-lg-none d-xl-none">

                <div id="partnerSmallExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">

                        <div class="carousel-item active" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_1.png" alt="وزارة التعليم">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_2.png" alt="الامير سطام">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_3.png" alt="مكتب التربية">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_4.png" alt="واحة الملك">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_5.png" alt="مدينة الملك">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_6.png" alt="واحة الملك 2">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_7.png" alt="STEM">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_8.png" alt="WRO">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_9.png" alt="Be Steam">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_10.png" alt="SME Bank">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_11.png" alt="منشات">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div
                                class="card-wrapper animate__animated animate__slideInDown animate__delay-0.9s animate__slow">
                                <div class="card">
                                    <div class="image-wrapper">
                                        <img src="@/assets/images/home/success_partners_12.png" alt="الهيئة الملكية">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex justify-content-center">
                        <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->
                        <!-- Carousel controls -->
                        <button class="carousel-control-next rounded" type="button"
                            data-bs-target="#partnerSmallExampleIndicators" data-bs-slide="next">
                            <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
                            <!-- <span class="visually-hidden">Next</span> -->
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                        <button class="carousel-control-prev rounded" type="button"
                            data-bs-target="#partnerSmallExampleIndicators" data-bs-slide="prev">
                            <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
                            <!-- <span class="visually-hidden">Previous</span> -->
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                    </div>

                </div>

            </div>

        </div>

        <!-- ******************************  End Container ************************************* -->

    </div>
</template>

<script>
export default {
    name: 'Partners',
}
</script>

<style lang="scss" scoped>
// ----------------- Partners ------------------------------ //
#partners {

    background-color: $homeBG;
    padding-top: 40px;
    padding-bottom: 40px;

    h3 {
        color: $heading;
        margin-bottom: 40px;
    }

    // For Large Screen

    @media screen and (min-width: 768px) {

        .large_screen .carousel .carousel-inner .carousel-item .card-wrapper {

            display: flex;

            .card {
                margin: auto 0.5em;
                width: calc(100%/3);
                box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);
            }

        }

    }

    // For Small Screen

    @media screen and (max-width: 768px) {

        .small_screen .carousel .carousel-inner .carousel-item .card-wrapper {
            .card {
                box-shadow: 0px 4px 4px 0px rgba(118, 118, 118, 0.25);
                margin-left: 2px;
                margin-right: 2px;
            }
        }

    }

    // For All Screen

    .carousel {

        .carousel-inner .carousel-item {

            transition: transform 2.5s ease-in-out;

            .card-wrapper .card {

                .image-wrapper {

                    height: 188px;
                    margin: 0 auto;
                    padding: 25px 50px;

                    img {

                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    // https://mdbootstrap.com/docs/standard/extended/slide-animation/
                    // https://mdbootstrap.com/docs/standard/content-styles/animations/
                    // https://thoughtbot.com/blog/transitions-and-transforms
                    // https://wowjs.uk/
                    // https://animate.style/
                    // https://www.youtube.com/watch?v=fC0Oid-wOD4

                    img:hover {
                        // transform: rotate(1080deg);
                        // transform: translate(20px, 20px);
                        // transform: translateY(100%);
                        // transition: transform 1s;
                        -webkit-animation: blinker 1s linear infinite;
                        -moz-animation: blinker 1s linear infinite;
                        -ms-animation: blinker 1s linear infinite;
                        -o-animation: blinker 1s linear infinite;
                        animation: blinker 1s linear infinite;
                    }

                }
            }

        }

        .carousel-control-prev,
        .carousel-control-next {
            position: static;
            padding-top: 16px;

        }

        .carousel-control-prev svg,
        .carousel-control-next svg {
            color: $Primary;
            width: 24px;
            height: 24px;
        }

    }

}

// For Animation
@-webkit-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-moz-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-o-keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}</style>