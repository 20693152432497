<!-- ############################## Start Template ########################################## -->

<template>
    <div class="container-fluid">

        <!-- ******************************  Start Slider ************************************* -->
        <Slider/>
        <!-- ******************************  End Slider ************************************* -->

        <!-- ******************************  Start Company Fields ************************************* -->
        <CompanyFields/>
        <!-- ******************************  End Company Fields ************************************* -->

        <!-- ******************************  Start Company Products ************************************* -->
        <CompanyProducts/>
        <!-- ******************************  End Company Products ************************************* -->

        <!-- ******************************  Start Abilities Pictures ************************************* -->

        <div class="abilities_pictures">
            <AbilitiesPictures />
        </div>

        <!-- ******************************  End Abilities Pictures ************************************* -->

        <!-- ******************************  Start Partners  ************************************* -->
        <Partners/>
        <!-- ******************************  End Partners  ************************************* -->

        <!-- ******************************  Start Customers  ************************************* -->
        <Customers/>
        <!-- ******************************  End Customers  ************************************* -->

        <!-- ******************************  Start Our Experts  ************************************* -->
        <OurExperts/>
        <!-- ******************************  End Our Experts  ************************************* -->

    </div>
</template>

<!-- ############################## End Template ########################################## -->

<!-- ############################## Start Script ########################################## -->

<script>

import Slider from '@/components/home/Slider.vue';
import CompanyFields from '@/components/home/CompanyFields.vue';
import CompanyProducts from '@/components/home/CompanyProducts.vue';
import AbilitiesPictures from '@/components/abilities_pictures/AbilitiesPictures.vue';
import Partners from '@/components/home/Partners.vue';
import Customers from '@/components/home/Customers.vue';
import OurExperts from '@/components/home/OurExperts.vue';


export default {

    name: 'Home',

    components: {
        Slider,
        CompanyFields,
        CompanyProducts,
        AbilitiesPictures,
        Partners,
        Customers,
        OurExperts,
    },

}

</script>

<!-- ############################## End Script ########################################## -->

<!-- ############################### Start Style ######################################### -->

<style lang="scss" scoped>
.container-fluid {
    margin: 64px 0 0 0;
    padding: 0;
}

// ----------------- Abilities Pictures ------------------------------ //

.abilities_pictures {
    margin-top: -64px;
}
</style>

<!-- ############################### End Style ######################################### -->